
import Vue from 'vue';
import Component from 'vue-class-component';
import dayjs from 'dayjs';
// Repository
import { RepositoryFactory } from '../repositories/RepositoryFactory';
const QuestionRepository = RepositoryFactory.get('question');

@Component
export default class QuestionStart extends Vue {
  // 回答日
  answer_date: any = {};
  // 質問紙ID
  questionnaire_id = 1;
  // 本日
  today = dayjs().format('YYYY-MM-DD');
  // 質問を質問群単位で表示するかどうか
  is_every_question_displayed_by_group = false;

  // 1ページ目（questionnaire_id/question_groupのindex/questionのindex）
  next(): void {
    this.$store.commit('answer/setAnswers', {});
    if (this.is_every_question_displayed_by_group) {
      this.$router.push('/questionnaire-by-group/' + this.questionnaire_id + '/1');
    } else {
      this.$router.push('/questionnaire/' + this.questionnaire_id + '/1/1');
    }
  }

  home(): void {
    this.$router.push('/index');
  }

  // Loadingの表示，非表示の制御
  toggleLoading(params: { is_loading: boolean; time: number }): void {
    this.$emit('toggleLoading', params);
  }

  // 回答予定日
  get startDate(): string {
    return this.answer_date.start_date;
  }

  // 質問紙名を読み込んだ後に画面に表示。読み込みの前はデフォルト値'0000-00-00'を表示
  get questionnaireName(): string {
    if (this.answer_date && this.answer_date.questionnaire && this.answer_date.questionnaire.name) {
      return this.answer_date.questionnaire.name;
    } else {
      return '0000-00-00';
    }
  }

  created(): void {
    // .envのVUE_APP_DISPLAY_QUESTIONS_BY_GROUP === '1'の場合、質問群単位で質問表示
    this.is_every_question_displayed_by_group = process.env.VUE_APP_DISPLAY_QUESTIONS_BY_GROUP === '1';
  }

  //
  async mounted(): Promise<any> {
    // ローディング
    this.toggleLoading({ is_loading: true, time: 0 });

    // 質問データを取得してstoreに保存
    const answer_dates = await this.$store.state.answer_dates.answer_dates;

    // 回答日（質問紙）データID
    const answer_date_id = await this.$route.params.answer_date_id;

    // 選択された回答日（質問紙）データ
    this.answer_date = answer_dates.filter((answer_date: any) => {
      return Number(answer_date.id) === Number(answer_date_id);
    })[0];
    // 選択されたanswer_dateをstoreに保存
    await this.$store.commit('answer_dates/setAnswerDate', this.answer_date);

    // 質問紙ID
    this.questionnaire_id = this.answer_date.questionnaire.id;
    // 質問リスト取得
    const questionnaire = (await QuestionRepository.questionnaire(this.questionnaire_id)).data.list;

    // 質問リストをstoreに保存
    await this.$store.commit('questionnaire/setQuestionnaire', questionnaire);

    // ブラウザバックを無効化
    addEventListener('popstate', () => {
      this.$router.push('/answer_date/' + this.answer_date.id + '/start');
    });

    // スクロールリセット
    window.scrollTo(0, 0);

    // ローディングOFF
    this.toggleLoading({ is_loading: false, time: 0.5 });
  }
}
